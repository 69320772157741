var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ls-category"},[_c('div',{staticClass:"ls-category__top ls-card"},[_c('el-alert',{attrs:{"title":"温馨提示：1.用户可以根据商品分类搜索商品，请正确创建分类；2.点击分类名称前符号，显示该商品分类的下级分类。","type":"info","show-icon":"","closable":false}})],1),_c('div',{staticClass:"ls-category__content m-t-16 ls-card"},[_c('div',{staticClass:"ls-content__btns"},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.$router.push('/goods/category_edit')}}},[_vm._v("新增商品分类")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pager.loading),expression:"pager.loading"}],staticClass:"ls-content__table m-t-16"},[_c('u-table',{ref:"plTreeTable",attrs:{"data":_vm.pager.lists,"fixed-columns-roll":"","height":800,"treeConfig":{
                    children: 'sons',
                    expandAll: false
                },"use-virtual":"","row-id":"id","border":false,"size":"mini"}},[_c('u-table-column',{attrs:{"tree-node":true,"prop":"name","label":"分类名称"}}),_c('u-table-column',{attrs:{"label":"分类图片"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.image)?_c('div',{staticClass:"flex"},[_c('el-image',{staticStyle:{"width":"58px","height":"58px"},attrs:{"fit":"cover","src":scope.row.image}})],1):_vm._e()]}}])}),_c('u-table-column',{attrs:{"label":"是否显示"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.handleStatus($event, scope.row.id)}},model:{value:(scope.row.is_show),callback:function ($$v) {_vm.$set(scope.row, "is_show", $$v)},expression:"scope.row.is_show"}})]}}])}),_c('u-table-column',{attrs:{"prop":"goods_num","label":"商品数量"}}),_c('u-table-column',{attrs:{"prop":"sort","label":"排序"}}),_c('u-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex"},[_c('div',[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({
                                            path: '/goods/category_edit',
                                            query: { id: scope.row.id }
                                        })}}},[_vm._v("编辑")]),_c('el-divider',{attrs:{"direction":"vertical"}})],1),_c('ls-dialog',{attrs:{"content":`确定删除：${scope.row.name}？请谨慎操作。`},on:{"confirm":function($event){return _vm.handleDelete(scope.row.id)}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"small"},slot:"trigger"},[_vm._v("删除")])],1)],1)]}}])})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }